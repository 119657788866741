import React, { useState, useEffect } from 'react';
import { Card, Table, Tag, Tooltip, message, Button, Divider,Spin } from 'antd';
import {
  EyeOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import AppLayout from '../../components/layout';
import Add from "../../images/new/plus_icon_yellow.svg"
import Register from './addUser';
import UserView from './UserView';
import API from '../../api';
import { Routes } from '../../utils/route';
import DataTable from '../../components/data-display/table/DataTable';
import { connect } from 'react-redux';
import {TextCapitalize,TextLowerCase} from '../../utils/helper'
import PageHeader from '../../components/pageHeader/PageHeader';
import ActionButton from "../../images/new/action_icon.svg"
const User = (props) => {
  const [state, setState] = useState({
    users: [],
    userProfileVisible: false,
    selectedUser: null,
  });
  const [role,setRole]=useState([])
  const [load, setLoad] = useState(true);
  const [loading, setLoading] = useState(true);
  const { userList } = API;
  useEffect(() => {
    const callback = (val) => {
      if (val === true) {
        setLoading(false);
        const roles=JSON.parse(localStorage.getItem('data'))
      const roleArr=[]
      roles.TabParams&&roles.TabParams.map((item)=>{
          if(item.param_value<100||item.param_value===199){
            roleArr.push({id: item.param_value,
              name: item.param_name,})
          }
         setRole(roleArr)
      })
        userList()
          .then((res) => {
            const _users = res.data.data;
            _users &&
              _users.map((item) => {
                item.name = item.first_name + ' ' + item.last_name;
              });
            setState({ ...state, users: _users });
            setLoad(false);
          })
          .catch(() => {
            setLoad(false);
          });
      }
    };
    Routes('manager', callback);
  }, []);

  useEffect(
    () => {
      const _users = [...users];
      _users.unshift(props.newUserData);
      setState({ ...state, users: _users });
    },
    [props.newUserData]
  );

  useEffect(
    () => {
      const _users = [...users];
      if (props.updateUserData !== undefined) {
        _users.filter((item, i) => {
          if (
            parseInt(props.updateUserData.user_id) === parseInt(item.user_id)
          ) {
            _users[i] = props.updateUserData;

          }
        });
      }
      setState({ ...state, users: _users });
    },
    [props.updateUserData]
  );

  useEffect(
    () => {
      const _users = [...users];
      _users &&
        _users.map((item) => {
          if (item.user_id === props.userId) {
            item.status = props.user_status;
          }
        });
      setState({ ...state, users: _users });
    },
    [props.userId, props.user_status]
  );

  const deleteUser = (userId) => {
    setState({
      ...state,
      users: state.users.filter((item) => item.client_id !== userId),
    });
    message.success({ content: `Deleted user ${userId}`, duration: 2 });
  };

  const showUserProfile = (userInfo) => {
    setState({
      ...state,
      userProfileVisible: true,
      selectedUser: userInfo,
    });
  };

  const closeUserProfile = () => {
    setState({
      ...state,
      userProfileVisible: false,
      selectedUser: null,
    });
  };
  const { users, userProfileVisible, selectedUser } = state;
  const filterRoles = (roleID) => {
    const result = role.filter((val, index) => {
      if (val.id === roleID) {
        return val;
      }
    });

    return result[0].name;
  };
  const tableColumns=[
    {name: "USER ID",label: "User Id",options: {filter: true,sort: true,customBodyRender: value =>TextCapitalize(value)}},
    {name: "NAME",label: "User Name",options: {filter: true,sort: true,customBodyRender: value =>TextCapitalize(value)}},
    {name: "E-MAIL",label: "Email",options: {filter: true,sort: true,customBodyRender: value =>value!=="NA"? TextLowerCase(value):"NA"}},
    {name: "ROLE",label: "Role",options: {filter: true,sort: true,customBodyRender: value =>TextCapitalize(value)}},
    {name: "STATUS",label: "Status",options: {filter: true,sort: true,customBodyRender: value =>{
      return <div>
      {(()=>{
        if(value===1){
          return <span style={{padding:"0px 6px",borderRadius:"2px",background:"#0FA36824",color:"#0FA368"}}>Active</span>
        }else{
          return <span style={{padding:"0px 6px",borderRadius:"2px",background:"#FD8F461A ",color:"#FD8F46"}}>Inactive</span>
        }
      })()}
  </div>
    }}},
    {name: "ACTION",label: "Action",options: {filter: false,sort: true,setCellProps: () => ({
      style: {
        whiteSpace: "nowrap",
        position: "sticky",
        right: "0",
        background: "#fff",
        zIndex: 100,
        textAlign: 'center',
      }
    }),
    setCellHeaderProps: () => ({
      style: {
        whiteSpace: "nowrap",
        position: "sticky",
        right: 0,
        color:'white',
        // background: "#1890ffd1",
        zIndex: 101
      }
    })}},
  ]
  const tableData=[]
  users&&users.map((item)=>{
    let roleName=""
    role&&role.map(
      (ele) =>
        parseInt(ele.id) === parseInt(item.role_id) && (
          roleName=ele.name.toUpperCase()
        )
    );
    tableData.push([item.user_id,item.first_name + ' ' + item.last_name,
    item.email ? item.email : 'NA',
    roleName,item.status,
    <img src={ActionButton} alt="icon" onClick={() => showUserProfile(item)} style={{cursor:"pointer"}}/>,
  ])
  })
  const columns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      render: (_, record) => (
        <span>{record.first_name + ' ' + record.last_name}</span>
      ),
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          a = a.name.toLowerCase();
          b = b.name.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: 'E-MAIL',
      dataIndex: 'email',
      showSorterTooltip: false,
      render: (_, record) => <p>{record.email ? record.email : 'NA'}</p>,
      sorter: {
        compare: (a, b) => {
          a = a.email.toLowerCase();
          b = b.email.toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: 'ROLE',
      dataIndex: 'role_id',
      render: (data) =>
        role.map(
          (item) =>
            parseInt(item.id) === parseInt(data) && (
              <span>{item.name.toUpperCase()}</span>
            )
        ),
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          a = filterRoles(a.role_id).toLowerCase();
          b = filterRoles(b.role_id).toLowerCase();
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      render: (status) => (
        <Tag className="text-capitalize" color={status === 0 ? 'red' : 'cyan'}>
          {status === 0 ? 'INACTIVE' : 'ACTIVE'}
        </Tag>
      ),
      showSorterTooltip: false,
      sorter: {
        compare: (a, b) => {
          a = a.status;
          b = b.status;
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },

    {
      title: '',
      dataIndex: 'actions',
      render: (_, elm) => (
        <div className="text-right">
          <Tooltip title="View">
            <Button
              type="primary"
              className="mr-2"
              icon={<EyeOutlined />}
              onClick={() => {
                showUserProfile(elm);
              }}
              size="small"
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  if(!loading){
  return (
    <AppLayout>
      <PageHeader button={true} title='yard user list' buttonContent={
      <Register
          Button={<><span style={{marginRight:"7px"}}><PlusOutlined /></span><span>add new user</span></>}
          title="ADD USER"
          custom={true}
          className="wash_po_add_edit_btn"
          /> }
          />
        <DataTable 
        loader={load}
        header={tableColumns}
        data={tableData}
        rowKey="id" />
        <UserView
          data={selectedUser}
          visible={userProfileVisible}
          close={() => {
            closeUserProfile();
          }}
        />
    </AppLayout>
  );
}
else{
  return(<div className="spinner-main"><Spin/></div>)
}
}
const mapStateToProps = (state) => ({
  loading: state.user.loading,
  user_status: state.user.user_status,
  newUserData: state.user.newUserData,
  updateUserData: state.user.updateUserData,
  userId: state.user.userId,
});
export default connect(mapStateToProps, null)(User);
