import { types } from "./type";
import API from '../api';
import {
    message
} from 'antd';
const { changeUserStatus, addUser, editUser } = API


export const AddUser = (data, callback) => (dispatch) => {
    dispatch({ type: types.SHOW_LOADING, loading: true })
    addUser(data).then((res) => {
        if (res.data.success === true) {
            callback(true)
            message.success({ content: `User Added`, duration: 2 });
            dispatch({
                type: types.ADD_USER,
                newUserData: res.data.data.userdata
            });
        } else {
            message.error({ content: `${res.data.message}`, duration: 2 });
        }
        dispatch({ type: types.SHOW_LOADING, loading: false })
    }).catch((err) => {
        message.error({ content: `${err}`, duration: 2 });
        callback(false)
        dispatch({ type: types.SHOW_LOADING, loading: false })
    })
};

export const EditUser = (data, callback) => (dispatch) => {
    dispatch({ type: types.SHOW_LOADING, loading: true })
    editUser(data).then((res) => {
        if (res.data.success === true) {
            callback(true)
            message.success({ content: `User Edited`, duration: 2 });
            dispatch({
                type: types.EDIT_USER,
                updateUserData: { ...data, user_id: data.id }
            });
        } else {
            message.error({ content: `${res.data.message}`, duration: 2 });
        }
        dispatch({ type: types.SHOW_LOADING, loading: false })
    }).catch((err) => {
        message.error({ content: `${err}`, duration: 2 });
        callback(false)
        dispatch({ type: types.SHOW_LOADING, loading: false })
    })
};



export const ChangeUserStatus = (data) => (dispatch) => {
    dispatch({ type: types.SHOW_LOADING, loading: true })
    changeUserStatus(data).then((res) => {
        if (res.data.success === true) {
            dispatch({
                type: types.CHANGE_USER_STATUS,
                user_status: data.status,
                userId: data.userId
            });
            //   navigate("/dashboard")
        } else {
            message.error({ content: `${res.data.message}`, duration: 2 });
        }
        dispatch({ type: types.SHOW_LOADING, loading: false })
    }).catch((err) => {
        message.error({ content: `${err}`, duration: 2 });
        dispatch({ type: types.SHOW_LOADING, loading: false })
    })
};