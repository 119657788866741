import React, { useEffect,useState } from 'react';
import { Avatar, Drawer, Divider,Switch,Tag } from 'antd';
import { 
	MobileOutlined, 
	MailOutlined, 
	UserOutlined, 
	CompassOutlined,
	CloseOutlined, CheckOutlined
} from '@ant-design/icons';
import Register from './addUser';
import { connect } from "react-redux"
import { ChangeUserStatus } from "../../actions/userAction"
import { usaFormatNumber } from '../../utils/helper';

const UserView =(props)=> {
		const { data, visible, close} = props;
		const [role,setRole]=useState()
		useEffect(()=>{
			const roles=JSON.parse(localStorage.getItem('data'))
			const roleArr=[]
			roles.TabParams&&roles.TabParams.map((item)=>{
				if(item.param_value<100||item.param_value===199){
				  roleArr.push({id: item.param_value,
					name: item.param_name,})
				}
			roleArr&&roleArr.map((ele) =>
				parseInt(ele.id) === parseInt(data?.role_id) && (
				  setRole(ele.name)
				)
			);
		})	
		},[visible])
		const changeStatus=(value)=>{
			const status={userId:data?.user_id,status:value===0?1:0}
			props.ChangeUserStatus(status)
		}
		const closePageHandler=()=>{
			close();
		}

		const userViewData=[
			{id:1,head:"User Name",details:data?.first_name+' '+data?.last_name },
			{id:2,head:"Customer Id",details:data?.clients_cust_id?data.clients_cust_id:'-'},
			{id:3,head:"Phone No",details:usaFormatNumber(data?.user_details?.mobile?data.user_details.mobile:"-")},
			{id:4,head:"Email",details:data?.email? data?.email: '-'},
			{id:5,head:"Role",details:role},
			{id:6,head:"Status",details:
			<div style={{display:"flex",padding:"10px 0px"}}>
				<Switch style={{background:data?.status?"#0FA368":"#FD8F46"}} loading={props.loading} onClick={()=>changeStatus(data.status)} checked={data?.status} checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
			    <div style={{marginLeft:"10px"}}>
                {(()=>{
                 if(data?.status===1){
                   return <span style={{padding:"0px 6px",borderRadius:"2px",background:"#0FA36824",color:"#0FA368"}}>Active</span>
                }else{
                  return <span style={{padding:"0px 6px",borderRadius:"2px",background:"#FD8F461A ",color:"#FD8F46"}}>Inactive</span>
                }
                })()}
                </div>
		    </div>},
		]
		return (
			<Drawer
				width={300}
				placement="right"
				onClose={closePageHandler}
				closable={false}
				visible={visible}
				title={<div className='wash_yard_drawer_head' style={{display:"flex",alignItems:"center",justifyContent:"space-between",padding:"10px 0px"}}>
					<span>user details</span>
					<span style={{cursor:"pointer"}} onClick={closePageHandler}><CloseOutlined /></span>
					</div>}
			>

             <div className='wash_customer_views' style={{minHeight:"200px"}}>
				{userViewData.map((item)=>{
					return <div key={item.id} className='wash_customer_views_text'>
					        <span className='wash_customer_views_text_left'>{item.head}:</span>
					        <span className='wash_customer_views_text_right' style={{color:item.id===4?"#38B6FF":""}}>{item.details}</span>
				            </div>
				})}
			</div>
			<div className='wash_customer_border' style={{top:"295px"}}></div>
			<div className='wash_delete_edit_customer'>
			<div className='wash_delete_customer'>delete</div>
			<Register custom={true} className="wash_edit_customer" user_id={data?.user_id} {...props} Button={<span>Edit</span>} title="Edit User"/>
			</div>
			</Drawer>
		)

}


const mapStateToProps = state => ({
	loading:state.user.loading,
	user_status:state.user.user_status
  })
  
  export default connect(mapStateToProps, { ChangeUserStatus })(UserView)