import React, { Component, useState, useEffect } from 'react';
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Button,
} from 'antd';
import Footer from '../../components/layout-components/modal/Footer';
import API from '../../api';
import { connect } from 'react-redux';
import { AddUser, EditUser } from '../../actions/userAction';
import { AddClientUser } from '../../actions/clientAction';
import {useWindowDimensions } from '../../utils/helper';
// import {UserDetails,clientPrimary} from './userform'
var moment = require('moment-timezone');
const { Option } = Select;
export const userRole = [
  {
    name: 'role_id',
    lable: 'Role',
    type: 'dropdown',
    value:[],
    rules: [
      {
        required: true,
        message: 'Please Enter Role!',
        whitespace: true,
      },
    ],
  },
];
export const UserDetails = [
  {
    name: 'first_name',
    lable: 'First Name',
    rules: [
      {
        required: true,
        message: 'Please Enter your first name!',
        whitespace: true,
      },
    ],
  },
  {
    name: 'last_name',
    lable: 'Last Name',
    rules: [
      {
        required: true,
        message: 'Please Enter your last name!',
        whitespace: true,
      },
    ],
  },
  {
    name: 'username',
    lable: 'User Name',
    rules: [
      {
        min: 6,
        message: 'username must be 6 character!',
      },

      {
        required: true,
        message: 'Please Enter your username!',
        whitespace: true,
      },
    ],
  },
  {
    name: 'password',
    lable: 'Password',
    type: 'password',
    hasFeedback: true,
    rules: [
      {
        required: true,
        message: 'Please Enter your password!',
        whitespace: true,
      },
    ],
  },
  {
    name: 'confirm',
    lable: 'Confirm Password',
    dependencies: ['password'],
    type: 'password',
    hasFeedback: true,
    rules: [
      {
        required: true,
        message: 'Please confirm your password!',
      },
      ({ getFieldValue }) => ({
        validator(rule, value) {
          if (!value || getFieldValue('password') === value) {
            return Promise.resolve();
          }

          return Promise.reject(
            'The two passwords that you entered do not match!'
          );
        },
      }),
    ],
  },
  {
    name: 'email',
    lable: 'Email Address',
    rules: [
      {
        type: 'email',
        message: 'The input is not valid E-mail!',
      },
    ],
  },
  {
    name: 'mobile',
    lable: 'Phone No.',
    rules: [
      {
        required: true,
        message: 'Please Enter your Mobile!',
        whitespace: true,
      },
    ],
  },
];
function assendingUserSort(val){
  return val.sort((a,b) => (a.displayValue.toUpperCase() > b.displayValue.toUpperCase()) ? 1 : ((b.displayValue.toUpperCase()> a.displayValue.toUpperCase()) ? -1 : 0))
}

const roleMap= () =>{
  const usersSorted = userRole
  return  usersSorted.map((form) => (
    <Col xs={{ span: 12}} lg={{ span: 8}}>
      <Form.Item
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        name={form.name}
        label={<span>{form.lable}</span>}
        initialValue={form.initialValue ? form.initialValue : ''}
        // initialValue={form.initialValue?form.initialValue:''}
        rules={form.rules}
      >
        <Select virtual={false} placeholder="Select Role">
        {form.value &&
              form.value.map((item) => (
                <Option value={item.value}>
                  {item.displayValue}
                </Option>
              ))}
        </Select>
      </Form.Item>
    </Col>
  ))
}
const RegistrationForm = (props) => {
  const [form] = Form.useForm();
  const [userDetails, setUserDetails] = useState(UserDetails);
  const [visible, setVisible] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const {getUserDetails} = API;
  const { height, width } = useWindowDimensions();
  useEffect(
    () => {
      const roles=JSON.parse(localStorage.getItem('data'))
      const roleArr=[]
      const user = [...userRole];

      roles.TabParams&&roles.TabParams.map((item)=>{
          if(item.param_key==="user_role"&&(item.param_value<100||item.param_value===199)){
            roleArr.push({value: item.param_value.toString(),
              displayValue: item.param_name,})
          }
          user.map((item)=>{
            if(item.name==="role_id"){
              item.value=roleArr
            }
          })
      })
      const sortedItemValues =assendingUserSort(user[0].value)
      user.value =sortedItemValues
      
      if (props.title === 'Edit User' && props.user_id !== undefined) {
        getUserDetails(props.user_id).then((res) => {
          const _data = {
            ...res.data.data,
            role_id: res.data.data.role_id.toString(),
          };
          form.setFieldsValue(_data);
        });
      }
    },
    [props.user_id, props.title]
  );
  const onFinish = (values) => {
    setBtnDisable(true);
    const timeZone = moment.tz.guess(true);
    const value = {
      ...values,
      name: values.name,
      timezone: timeZone,
    };
    if (value.email === '') {
      delete value['email'];
    }
    const callback = (val) => {
      if (val === true) {
        form.resetFields();
        setBtnDisable(false);

        setVisible(false);
        if (props.from === 'admin') {
          props.addUserViewTab(true);
        }
      } else {
        setBtnDisable(false);
      }
    };
    if (props.role_disable === undefined) {
      if (props.title === 'Edit User') {
        const data = { ...value, id: props.user_id.toString() };
        props.EditUser(data, callback);
      } else {
        props.AddUser(value, callback);
      }
    } else {
      if (props.title !== 'Edit User') {
        props.AddClientUser(
          { ...value, role_id: 99 },
          props.client_id,
          callback
        );
      } else {
        const data = { ...value, id: props.user_id.toString() };
        props.EditUser(data, callback);
      }
    }
  };

  return (
    <Footer
    fullWidth={width<991?true:false}
      setVisible={(e) => setVisible(e)}
      visible={visible}
      Button={props.Button}
      title={props.title}
      custom={props.custom}
      className={props.className}
      submit={
        <Form.Item>
          <Button
            disabled={btnDisable}
            type="primary"
            form={props.user_id ? `editUser${props.user_id.toString()}` : 'newUser'}
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      }
    >
      <Form
        form={form}
        id={props.user_id ? `editUser${props.user_id.toString()}` : 'newUser'}
        name="register"
        onFinish={onFinish}
        scrollToFirstError
      >
        <Row gutter={24}>
         
          {props.role_disable === undefined &&
            userRole && roleMap()
            }
          {userDetails &&
            userDetails.map((form) => (
              <Col  xs={{ span: 12}} lg={{ span: 8}}>
                <Form.Item
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  name={form.name}
                  label={<span>{form.lable}</span>}
                  initialValue={form.initialValue ? form.initialValue : ''}
                  // initialValue={form.initialValue?form.initialValue:''}
                  rules={form.rules}
                >
                  {form.type === 'password' ? <Input.Password /> : <Input />}
                </Form.Item>
              </Col>
            ))}
        </Row>
      </Form>
    </Footer>
  );
};

export class Register extends Component {
  render() {
    return <RegistrationForm {...this.props} />;
  }
}
const mapStateToProps = (state) => ({
  loading: state.user.loading,
  user_status: state.user.user_status,
});

export default connect(mapStateToProps, { AddUser, EditUser, AddClientUser })(
  Register
);
