import {types} from './type';
import API from '../api';
import {message} from 'antd';
const {
  changeClientStatus,
  clientList,
  addClientUser,
  addClients,
  EditClients,
} = API;

export const ChangeClientStatus = data => dispatch => {
  dispatch ({type: types.SHOW_LOADING, loading: true});
  changeClientStatus (data).then (res => {
    if (res.data.success === true) {
      dispatch ({
        type: types.CHANGE_CLIENT_STATUS_FROM_TABLE,
        client_status: data.status,
        clientId: data.clientId,
      });
    }
    dispatch ({type: types.SHOW_LOADING, loading: false});
  });
};

export const ClientData = () => async dispatch => {
  dispatch ({type: types.SHOW_LOADING, loading: true});
  const data = await clientList ();
  data.data.data.map (item => {
    item.name = item.first_name + ' ' + item.last_name;
  });
  dispatch ({
    type: types.STORE_CLIENT_LIST_DATA,
    payload: data.data.data,
  });

  dispatch ({type: types.SHOW_LOADING, loading: false});
};

export const AddClient = (data, callback) => dispatch => {
  dispatch ({type: types.SHOW_LOADING, loading: true});
  addClients (data).then (res => {
    if (res.data.success === true) {
      data.name = data.first_name + ' ' + data.last_name;

      data.client_id = res.data.data.client_id;
      callback (true);
      dispatch ({
        type: types.ADD_CLIENT,
        newClientData: data,
      });
    }
    dispatch ({type: types.SHOW_LOADING, loading: false});
  });
};

export const EditClient = (data, callback) => dispatch => {
  dispatch ({type: types.SHOW_LOADING, loading: true});
  EditClients (data).then (res => {
    if (res.data.success === true) {
      data.name = data.first_name + ' ' + data.last_name;

      callback (true);
      dispatch ({
        type: types.EDIT_CLIENT,
        updateClientData: data,
      });
    }
    dispatch ({type: types.SHOW_LOADING, loading: false});
  });
};

export const AddClientUser = (data, client_id, callback) => dispatch => {
  dispatch ({type: types.SHOW_LOADING, loading: true});
  addClientUser (data, client_id)
    .then (res => {
      if (res.data.success === true) {
        message.success ({content: `User Added`, duration: 2});

        callback (true);
        // dispatch ({
        //   type: types.CHANGE_CLIENT_STATUS_FROM_TABLE,
        //   client_status: data.status,
        //   clientId: data.clientId,
        // });
      } else {
        message.error ({content: `${res.data.message}`, duration: 2});
      }
      dispatch ({type: types.SHOW_LOADING, loading: false});
    })
    .catch (err => {
      message.error ({content: `${err}`, duration: 2});
      callback (false);
    });
};
